import axios from "axios";
axios.defaults.withCredentials = true;
const access_token = localStorage.getItem('auth-token') || '';

export default axios.create({
  baseURL: 'https://api.channelassets.net',
  headers: {
    "Content-type": "application/json",
    withCredentials: true,
    Authorization: `Bearer ${access_token}`
  }
});